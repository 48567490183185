import React, { useEffect, useState, useContext, useRef, useMemo } from "react";

import Context from "../../../context";

import PropTypes from "prop-types";

import { Paper, Typography, Grid, Button, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FFSelectTree from "../../../components/FFSelectTree";

import FFTreeEssenceChecked from "../../../components/FFTreeEssenceChecked";
import FFSwitch_N from "../../../libft/FFSwitchN_new";

import { SnackbarProvider, useSnackbar } from "notistack";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AsyncTree } from "../../../components/tree-async/tree-async";
import { listToTree } from "../../../utils/list-to-tree";
/*
 **-------------------- My Components ---------------------------
 */
import FFTextField from "../../../libft/FFTextField";
import FFContainer from "../../../libft/FFContainer";
import FFTreeEssence from "../../../libft/FFTreeEssence";
import FFActionButton from "../../../libft/FFActionButton";
import FFSelect from "../../../libft/FFSelect";
import FFLoader from "../../../libft/FFLoader";
import FFLoaderComponent from "../../../libft/FFLoaderComponent";
import FFDialogDelete from "../../../libft/FFDialogDelete";
import FFDialogEditTemplate from "./dialogEdit/FFDialogEditTemplate";
import FFActionAvatar from "../../../libft/FFActionAvatar";
import FFDialogManipulationTree from "./dialogs/FFDialogManipulationTree";
/*
 **------------------- My Page Components -------------------------
 */

import FF_MassiveParameters from "./FF_EssenceMassiveParameters";
import FFEssenceCard from "./FFEssenceCard";

import {
  ApiConnect,
  urlConfiguration,
  urlReader,
} from "../../../ApiConnectNew";

import clsx from "clsx";
import TreeNodeUtils from "tree-node-utils";
import cookie from "react-cookies";
import { api } from "../../../api/api";

import { useSelector } from 'react-redux'
import { getIsFull } from "../../../../features";

import {v4 as uuidv4} from 'uuid'

const newStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
}));

export default function FF_Essence(props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  const classes = newStyles();

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const config = {
    childrenField: "children",
    keyField: "id",
  };
  // const treeUtils = new TreeNodeUtils(config)

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const metaEssence = {
    label: {
      label: "Наименование",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    label_eng: {
      label: "Наименование на латинском",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    template: {
      label: "Используемый шаблон",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    templateLabel: {
      label: "Используемый шаблон",
      is_unique: false,
      is_required: false,
      is_type: "string",
    },
    name: {
      label: "Системное наименование",
      is_unique: true,
      is_required: true,
      is_type: "string",
    },
    description: {
      label: "Описание",
      is_unique: false,
      is_required: false,
      is_type: "string",
    },
  };

  const strAuthorization = "Bearer " + cookie.load("access_token");
  const { isLogin } = useContext(Context);
  const [essence, setEssence] = useState([]);
  const [essenceLoading, setEssenceLoading] = useState(false);
  const [activeEssence, setActiveEssence] = useState(null);
  const [children, setChildren] = useState({});
  const [isEdit, setIsEdit] = useState(0);
  const [te, setTe] = useState(0);
  const [classifier, setClassifier] = useState([]);
  const [classifiers, setClassifiers] = useState([]);

  const [template, setTemplate] = useState([]);
  const [parameter, setParameter] = useState([]);
  const [dialogToCreate, setDialogToCreate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [tempTemplate, setTempTemplate] = useState("");
  const [clickChecked, setClickChecked] = useState(true);
  const [listChecked, setListChecked] = useState([]);
  const [activeParameter, setActiveParameter] = useState("");
  const [essenceParameter, setEssenceParameter] = useState([]);
  const [client, setClient] = useState({});
  const [dialogToCopy, setDialogToCopy] = useState(false);
  const [curParent, setCurParent] = useState("");
  const [activeEssenceLoading, setActiveEssenceLoading] = useState(false);
  const [curParentChild, setCurParentChild] = useState(0);
  const [activeEssenceId, setActiveEssenceId] = useState(null);
  const [essenceMassive, setEssenceMassive] = useState(false);
  const [openSCVDialog, setOpenCSVDialog] = useState(false);
  const [openChangeParent, setOpenChangeParent] = useState(false);
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [isOpenDialogEditTmplate, setIsOpenDialogEditTmplate] = useState(false);
  const [isOpenManipulationTree, setIsOpenManipulationTree] = useState(false);
  const [updateFilter, setUpdateFilter] = useState(uuidv4());
  const [keyFilter, setKeyFilter] = useState(null);
  const isFull = useSelector(getIsFull);

  const [clients, setClients] = useState([]);

  const [filterTree, setFilterTree] = useState({
    is_panel: {
      value: null,
      options: [
        { id: "1", label: "Присутствует" },
        { id: "0", label: "Отсутствует" },
      ],
      title: "Наличие панелей управления",
      is_active: false,
      label: "",
      type: "FFSelect",
      icon: "panel",
    },
    id_template: {
      value: [],
      options: null,
      is_and: -1,
      title: "Шаблон",
      label: "",
      is_active: false,
      type: "FFSelect",
      icon: "template",
    },
    id_classifier: {
      value: [],
      options: null,
      is_and: 1,
      title: "Классификатор",
      is_active: false,
      type: "FFSelectTree",
      icon: "classifier",
    },
    // id_classifier_value: {
    //   value: null,
    //   options: null,
    //   title: 'Классификационное значение',
    //   is_active: false,
    //   type: 'FFSelectTree',
    // },
    id_parameter: {
      value: [],
      is_and: 1,
      options: null,
      title: "Параметр сущности",
      is_active: false,
      label: "",
      type: "FFSelectAndText",
      icon: "parameter",
    },
    // id_parameter_value: {
    //   value: null,
    //   options: null,
    //   title: 'Значение параметра',
    //   is_active: false,
    //   label: '',
    //   type: 'FFTextBox',
    //   icon: 'value'
    // },
    is_stream_baseline: {
      value: null,
      options: [
        { id: "1", label: "Присутствует" },
        { id: "0", label: "Отсутствует" },
      ],
      title: "Наличие стримов для базовой линии",
      is_active: false,
      type: "FFSelect",
      icon: "chart",
    },
  });

  useEffect(() => {
    setUpdateFilter(uuidv4());
  }, [filterTree]);

  useEffect(() => {
    if (templates.length) {
      const temp = filterTree;
      temp["id_template"].options = templates;
      setFilterTree(temp);
    }
  }, [templates]);

  const getClassifiers = () => {
    ApiConnect({
      name: "getData",
      url: urlConfiguration + "/api/config/ontological_classifier/tree/",
      setterEssence: (temp) => {
        const tempFilterTree = filterTree;
        tempFilterTree["id_classifier"].options = temp?.children || [];
        setFilterTree(tempFilterTree);
      },
      setterLoading: null,
      isLogin: isLogin,
    });
  };

  const getParameters = () => {
    ApiConnect({
      name: "getData",
      url: `${urlConfiguration}/api/?entity=parameter_registry&is_full=false`,
      setterEssence: (temp) => {
        const tempFilterTree = filterTree;
        tempFilterTree["id_parameter"].options = temp;
        setFilterTree(tempFilterTree);
      },
      setterLoading: null,
      isLogin,
    });
  };
  useEffect(() => {
    getClassifiers();
    getParameters();
    let parameterByEssences = JSON.parse(
      localStorage.getItem("parameterByEssences")
    );
    if (parameterByEssences) {
      setClickChecked(parameterByEssences.clickChecked);
      setListChecked(parameterByEssences.obj.map((obj) => obj.id));
      ApiConnect({
        name: "getDataPost",
        url: urlConfiguration + "/api/config/essence/list_parameter_update/",
        objToGet: {
          essences: parameterByEssences.obj.map((obj) => obj.id),
          parameter: parameterByEssences.activeParameter,
        },
        setterEssence: setEssenceParameter,
        setterLoading: null,
        isLogin: isLogin,
      });
      doActiveParameter(parameterByEssences.activeParameter);
      setEssenceMassive(parameterByEssences.essenceMassive);
    }
    ApiConnect({
      name: "getData",
      url: urlConfiguration + "/api/?entity=parameter_registry",
      setterEssence: setParameter,
      setterLoading: null,
      isLogin: isLogin,
    });
    doRefresh();
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  /**
   *  Отображения дравера для манипуляциия над деревом
   */
  const doManipulationTree = () => {};
  const toGoRecur = (node, lstChecked, cmd) => {
    node.children.map((obj) => toGoRecur(obj, lstChecked, cmd));
    node["checked"] = lstChecked.indexOf(node.id) > -1 ? true : false;
    node["indeterminate"] = false;
    if (cmd === "mult") {
      let ind = node.children.filter((obj) => obj.checked === true);
      if (node.children.length) {
        if (
          ind.length &&
          ind.length == node.children.length &&
          node.children.filter(
            (obj) => obj.checked === true && obj.indeterminate === false
          ).length === node.children.length
        ) {
          node["indeterminate"] = false;
          node["checked"] = true;
        } else if (ind.length) {
          node["indeterminate"] = true;
          node["checked"] = true;
        } else {
          node["indeterminate"] = false;
          node["checked"] = false;
        }
      }
    }
  };

  useEffect(() => {
    if (essence.length) {
      essence.map((obj) => toGoRecur(obj, [], "mult"));
      setEssence(essence);
      // setEssenceLoading(true)
    }
  }, [essence]);

  // let all = childrenActiveEssence.map(device => {
  //   return { ...device, ...checkDevice(device.id) }
  // })
  // all.sort((a, b) => {
  //   if (a.label > b.label) {
  //     return 1
  //   }
  //   if (a.label < b.label) {
  //     return -1
  //   }
  //   return 0
  // })
  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj) => {
    console.log(obj, objId, essence);
    if (essence.id !== objId && obj.type !== "client") {
      setChildren(obj);
      setClient(null);
      setActiveEssenceId(obj.id);
      setActiveEssenceLoading(false);
      setActiveEssence(null);
      ApiConnect({
        name: "getData",
        url: urlConfiguration + "/api/config/essence/?id=" + objId,
        setterEssence: setActiveEssence,
        setterLoading: setActiveEssenceLoading,
        isLogin: isLogin,
      });
    } else {
      setClient(obj);
      setActiveEssenceId(null);
      setActiveEssenceLoading(false);
      setActiveEssence(null);
    }
  };

  useEffect(() => {
    if (activeEssence) {
      ApiConnect({
        name: "getData",
        url:
          urlConfiguration +
          "/api/?entity=template_essence&id=" +
          activeEssence.template,
        setterEssence: setTemplate,
        setterLoading: null,
        isLogin: isLogin,
      });
      ApiConnect({
        name: "getDataPost",
        url:
          urlConfiguration + "/api/config/ontological_classifier/tree/?mode=id",
        objToGet: {
          names: Object.keys(activeEssence.active_classifier_values),
        },
        setterEssence: setClassifier,
        setterLoading: null,
        isLogin: isLogin,
      });
    }
  }, [activeEssence]);

  useEffect(() => {
    if (template.length && activeEssence) {
      let temp = activeEssence;
      temp.templateLabel = template[0].label;
      setActiveEssence(temp);
      setTe(uuidv4());
      //   setActiveEssence(temp)
    }
  }, [template]);

  // Обработчик на кнопку добавления
  const doAddEssence = () => {
    doClickEditParent();
  };

  // Обработчик для копирования
  const doAddCopyEssence = () => {
    if (activeEssence) {
      setCurParent(activeEssence.parent);
      setCurParentChild(0);
      setDialogToCopy(true);
    } else {
      handleClickVariant("warning", "Выберите копируемое значение");
    }
  };

  const toCopy = () => {
    let parent = curParent;
    let isRecur = curParentChild;
    if (essence.filter((obj) => obj.id === parent).length > 0) {
      parent = null;
    }
    if (isRecur === 0) {
      let temp = {};
      let tempId = uuidv4();
      let time = new Date().toLocaleString();
      temp.id = tempId;
      temp.name = `${activeEssence.name}(copy ${time})`;
      temp.label = `${activeEssence.label}(copy ${time})`;
      temp.label_eng = `${activeEssence.label_eng}(copy ${time})`;
      // temp.name = 'name' + '_' + tempId.split('-')[0]
      // temp.label = '0_New_label' + '_' + tempId.split('-')[0]
      // temp.label_eng = 'label_eng' + '_' + tempId.split('-')[0]
      temp.active_classifier_values = activeEssence.active_classifier_values;
      temp.active_parameter_values = {};
      temp.parameter_values = activeEssence.parameter_values;
      temp.parent = parent;
      temp.client = activeEssence.client;
      temp.template = activeEssence.template;
      ApiConnect({
        name: "createData",
        url: urlConfiguration + "/api/?entity=essence",
        objToCreate: temp,
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
      setTimeout(() => {
        ApiConnect({
          name: "getData",
          url: urlConfiguration + "/api/config/essence/?id=" + temp.id,
          setterEssence: setActiveEssence,
          setterLoading: null,
          isLogin: isLogin,
        });
      }, 1000);
    } else {
      if (parent === null) {
        ApiConnect({
          name: "createData",
          url:
            urlConfiguration +
            "/api/essence/deep_copy/?start_essence=" +
            activeEssence.id,
          objToCreate: {},
          isLogin: isLogin,
          clickHandle: handleClickVariant,
        });
      } else {
        ApiConnect({
          name: "createData",
          url:
            urlConfiguration +
            "/api/essence/deep_copy/?start_essence=" +
            activeEssence.id +
            "&new_parent_essence=" +
            parent,
          objToCreate: {},
          isLogin: isLogin,
          clickHandle: handleClickVariant,
        });
      }
    }
    doRefresh();
    setDialogToCopy(false);
  };

  const customEssences = (clientsApi) => (essences) => {
    let tree = listToTree(essences, { parentId: "parent_id" });
    tree = listToTree([...tree, ...clientsApi], { parentId: "client_id" });
    tree = tree.sort((a, b) => (a.label - b.label ? -1 : 1));
    console.log(tree, essenceLoading);
    setEssence(tree);
  };

  // Обработчик для обновления
  const doRefresh = () => {
    setEssenceLoading(false);
    setTimeout(() => {
      api.essence.get_tree({
        isLogin,
        callback: setEssence,
        callbackLoading: setEssenceLoading,
      });

      ApiConnect({
        name: "getData",
        url: urlConfiguration + "/api/?entity=template_essence",
        setterEssence: setTemplates,
        setterLoading: null,
        isLogin: isLogin,
      });
    }, 0);
  };

  // const treeControl = (
  //   <AsyncTree
  //     setEssence={setEssence}
  //     setEssenceLoading={setEssenceLoading}
  //     essenceLoading={essenceLoading}
  //   />
  // );
  const treeControl = useMemo(
    () => (
      <FFTreeEssence
        essence={[...essence]}
        doubleClick={1}
        active={activeEssenceId}
      />
    ),
    [essence, essenceLoading]
  );

  // Обработчик для удаления
  const doDeleteEssence = () => {
    if (activeEssence) {
      ApiConnect({
        name: "deleteDate",
        url: urlConfiguration + "/api/?entity=essence&id=" + activeEssence.id,
        objToDelete: { id: activeEssence.id },
        clickHandle: handleClickVariant,
        isLogin: isLogin,
      });
    }
    setActiveEssence(null);
    doRefresh();
  };

  const doSaveEssence = () => {
    if (JSON.stringify(activeEssence).indexOf("ValidationError") > -1) {
      handleClickVariant("warning", "Ошибка валидации");
    } else {
      delete activeEssence.is_deleted;
      ApiConnect({
        name: "updateDate",
        url: urlConfiguration + "/api/?entity=essence",
        objToUpdate: activeEssence,
        clickHandle: handleClickVariant,
        isLogin: isLogin,
      });
      api.essence.get_tree({
        isLogin,
        callback: setEssence,
        callbackLoading: setEssenceLoading,
      });
    }
    // ApiConnect({
    //   name: 'updateDate',
    //   url: '/api/?entity=essence',
    //   objToUpdate: activeEssence,
    //   isLogin: props.isLoginNo
    // })
  };

  // Обработчик для текстовых полей
  const changeTextField = (value, parameter_id, name, element) => {
    let temp = activeEssence;
    temp[name] = value;
    setActiveEssence(temp);
    return "";
  };

  const getBackToParent = (temp) => {
    setActiveEssence(temp);
  };

  const doClickEditParent = () => {
    setDialogToCreate(true);
  };

  const closeParentDialog = () => {
    setTempTemplate("");
    setDialogToCreate(false);
  };

  const closeAgreeParentDialog = () => {
    setDialogToCreate(false);
    if ((client || activeEssence) && tempTemplate && numAddEssence > 0) {
      let i = -1;
      while (++i < numAddEssence) {
        let temp = {};
        let tempId = uuidv4();
        temp.id = tempId;
        temp.name = "name" + "_" + tempId.split("-")[0];
        temp.label = "0_New_label" + "_" + tempId.split("-")[0];
        temp.label_eng = "label_eng" + "_" + tempId.split("-")[0];
        temp.active_classifier_values = {};
        temp.active_parameter_values = {};
        temp.parent = activeEssence ? activeEssence.id : null;
        temp.client = activeEssence ? activeEssence.client : client.id;
        temp.template = tempTemplate;
        ApiConnect({
          name: "createData",
          url: urlConfiguration + "/api/?entity=essence",
          objToCreate: temp,
          isLogin: isLogin,
          clickHandle: handleClickVariant,
        });
      }
      doRefresh();
    } else {
      handleClickVariant(
        "warning",
        "Выберите сущность на основе которой вы создаете новую сущность"
      );
    }
  };

  // Checked в дереве
  const doClickCheckedEssence = (temp, cmd) => {
    essence.map((obj) => toGoRecur(obj, temp, cmd));
    setListChecked(temp);
    if (activeParameter !== "") {
      setEssenceMassive(false);
      ApiConnect({
        name: "getDataPost",
        url: urlConfiguration + "/api/config/essence/list_parameter_update/",
        objToGet: { essences: temp, parameter: activeParameter },
        setterEssence: setEssenceParameter,
        setterLoading: setEssenceMassive,
        isLogin: isLogin,
      });
      // setTimeout(() => {
      //   setTe(te + 1)
      // }, 10)
    }
  };

  const doActiveParameter = (value) => {
    setActiveParameter(value);
    setEssenceMassive(false);

    setEssenceParameter([]);
    if (listChecked.length > 0) {
      ApiConnect({
        name: "getDataPost",
        url: urlConfiguration + "/api/config/essence/list_parameter_update/",
        objToGet: { essences: listChecked, parameter: value },
        setterEssence: setEssenceParameter,
        setterLoading: setEssenceMassive,
        isLogin: isLogin,
      });
    }
  };

  const getBackParameter = (temp) => {
    setEssenceParameter(temp);
  };

  const doSaveEssenceParameter = () => {
    ApiConnect({
      name: "updateDate",
      url: urlConfiguration + "/api/config/essence/list_parameter_update/",
      objToUpdate: essenceParameter.filter((obj) => obj.parameter !== null),
      clickHandle: handleClickVariant,
      isLogin: isLogin,
    });
    // updateData(
    //   '/api/config/essence/list_parameter_update/',
    //   essenceParameter.filter(obj => obj.parameter !== null)
    // )
  };

  const doChangeSelect = (par, obj) => {
    setCurParent(obj);
  };

  const doGoPositionScroll = (tempPosition) => {
    // console.log(position)
    // setPosition(tempPosition)
  };

  const doClickMetaSelect = (tempSelect) => {
    // setSelect(tempSelect)
  };

  const handleChangeFFTextFields = (value, id, name, element) => {
    console.log(value, id, name, element);
    if (element) {
      setCurParentChild(value);
    }
  };

  const [numAddEssence, setNumAddEssence] = useState(1);

  const changeValue = (value, id, name, element) => {
    if (name === "template") {
      setTempTemplate(value);
    }
    if (name === "numAddEssence") {
      if (isNaN(Number(value)) || Number(value) <= 0)
        return "Укажите положительное число";
      else setNumAddEssence(Number(value));
      return;
    }
  };

  const updateTree = () => {
    setEssence([]);
    setEssenceLoading(false);
    const obj = {};
    filterTree.is_panel?.value &&
      (obj["is_panel"] = Boolean(Number(filterTree.is_panel.value)));
    filterTree.is_stream_baseline?.value &&
      (obj["is_stream_baseline"] = Boolean(
        Number(filterTree.is_stream_baseline.value)
      ));
    filterTree.id_template?.value?.length &&
      (obj["id_template"] = filterTree.id_template?.value);
    filterTree.id_classifier?.value?.length &&
      (obj["id_classifier"] = {
        value: filterTree.id_classifier?.value,
        is_and: Boolean(filterTree.id_classifier?.is_and),
      });
    filterTree.id_parameter?.value?.length &&
      (obj["id_parameter"] = {
        value: filterTree.id_parameter?.value.map((obj) => {
          if (!obj.is_active) {
            return [obj.id_parameter];
          }
          // if (
          //   (typeof obj.value === 'string' && obj.value.length === 0) ||
          //   (!obj.value && obj.is_active)
          // ) {
          //   return [obj.id_parameter, 'empty']
          // }
          return [obj.id_parameter, obj.value];
        }),
        is_and: Boolean(filterTree.id_parameter?.is_and),
      });
    if (Object.keys(obj).length) {
      api.essence.filter({
        body: obj,
        callback: (temp) => {
          if (temp?.length) {
            setEssence(temp);
          } else {
            handleClickVariant("warning", "Ничего не найдено");
            doRefresh();
          }
        },
        callbackLoading: setEssenceLoading,
        isLogin: isLogin,
      });
    } else {
      doRefresh();
    }
  };

  /**
   * Фунция для смены родителя сущности, точнее открытия диалога и тд
   */
  const doChangeParent = () => {
    setOpenChangeParent(true);
  };

  const toShowManipulationTree = (key) => {
    console.log(key);
    setKeyFilter(key);
    setIsOpenManipulationTree(true);
  };
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        doClickMetaSelect,
        doGoPositionScroll,
        doClickActiveEssence,
        doClickCheckedEssence,
        changeTextField,
        getBackToParent,
        changeValue,
      }}
    >
      {openSCVDialog && (
        <DialogSCV
          open={openSCVDialog}
          setOpen={setOpenCSVDialog}
          essence={essence}
          openBackDrop={openBackDrop}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}
      {openChangeParent && (
        <DialogChangeParent
          open={openChangeParent}
          setOpen={setOpenChangeParent}
          essence={essence}
          activeEssence={activeEssence}
          doRefresh={doRefresh}
        />
      )}
      {activeEssence ? (
        <Dialog open={dialogToCopy} key={"copyEssence"} maxWidth="sm" fullWidth>
          <DialogTitle>{"Что будем копировать?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Context.Provider value={{ doChangeSelect }}>
                    <FFSelectTree
                      key={"FFSelectTree_" + "temp"}
                      stateMeta={{ open: false, active: -1, label: "" }}
                      idCls={"temp"}
                      selectValue={curParent}
                      selectMetaLabel={"Куда скопировать?"}
                      selectNodes={essence}
                    />
                  </Context.Provider>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "-webkit-center" }}>
                  <Context.Provider value={{ changeValue: handleChangeFFTextFields }}>
                    <FFSwitch_N
                      id={"boolChildtoCopy"}
                      meta={{
                        label: [
                          "Текущий узел",
                          "Текущий узел + потомки",
                        ],
                        width: "400",
                        value: curParentChild,
                      }}
                    />
                  </Context.Provider>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toCopy()} color="secondary">
              Скопировать
            </Button>
            <Button onClick={() => setDialogToCopy(false)} color="primary">
              Вернутся к редактированию
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <Dialog
        open={dialogToCreate}
        onClose={closeParentDialog}
        key={"newEssence"}
      >
        <DialogTitle>{"Укажите шаблон"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FFContainer
              is_row={true}
              id={"all"}
              components={[
                {
                  width: 9,
                  elevation: 0,
                  component: (
                    <FFSelect
                      value={tempTemplate}
                      options={templates}
                      label={"Шаблон"}
                      name={"template"}
                    />
                  ),
                },
                {
                  width: 3,
                  elevation: 0,
                  component: (
                    <FFTextField
                      value={numAddEssence}
                      label={"Количество"}
                      name={"numAddEssence"}
                    />
                  ),
                },
              ]}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAgreeParentDialog} color="secondary">
            Создать
          </Button>
          <Button onClick={closeParentDialog} color="primary">
            Вернутся к редактированию
          </Button>
        </DialogActions>
      </Dialog>

      {isOpenDialogDelete && (
        <FFDialogDelete
          open={isOpenDialogDelete}
          setOpen={setIsOpenDialogDelete}
          isAgree={(e) => doDeleteEssence()}
        />
      )}

      {isOpenManipulationTree && keyFilter.length && (
        <FFDialogManipulationTree
          open={isOpenManipulationTree}
          setOpen={setIsOpenManipulationTree}
          keyFilter={keyFilter}
          objFilter={filterTree[keyFilter]}
          updateTree={updateTree}
        />
      )}

      {isOpenDialogEditTmplate && (
        <FFDialogEditTemplate
          open={isOpenDialogEditTmplate}
          setOpen={setIsOpenDialogEditTmplate}
          templates={templates}
          activeEssence={activeEssence}
          handleClickVariant={handleClickVariant}
        />
      )}
      <Grid container>
        <Grid
          xs={activeEssence ? 4 : 7}
          container
          style={{ alignItems: "center" }}
        ></Grid>
        <Grid item xs={activeEssence ? 8 : 5} align="right">
          <FFActionButton
            type={"list"}
            onClick={() => {
              setClickChecked(!clickChecked);
              setListChecked([]);
              setActiveEssence(null);
              setActiveParameter("");
              setEssenceParameter([]);
              localStorage.removeItem("parameterByEssences");
            }}
            tooltip={"Массовое заполнение параметров"}
            className={classes.margin}
          />
          {essence.length ? (
            <FFActionButton
              type={"download"}
              onClick={() => setOpenCSVDialog(true)}
              tooltip={"Скачать"}
              className={classes.margin}
            />
          ) : null}
          {clickChecked ? (
            <FFActionButton
              type={"add"}
              onClick={() => doAddEssence()}
              tooltip={"Создать сущность"}
              disabled={!isFull}
              color={!isFull ? 'gray' : 'primary'}
              className={classes.margin}
              style={{ visibility: isEdit === 0 ? "visible" : "hidden" }}
            />
          ) : null}
          {clickChecked ? (
            <FFActionButton
              type={"copy"}
              onClick={() => doAddCopyEssence()}
              tooltip={"Копировать выбранную сущность"}
              disabled={!isFull}
              color={!isFull ? 'gray' : 'primary'}
              className={classes.margin}
              style={{ visibility: isEdit === 0 ? "visible" : "hidden" }}
            />
          ) : null}
          {clickChecked && activeEssence !== null ? (
            <FFActionButton
              type={"save"}
              onClick={() => doSaveEssence()}
              tooltip={"Сохранить"}
              disabled={!isFull}
              color={!isFull ? 'gray' : 'primary'}
              className={classes.margin}
              style={{ visibility: isEdit === 0 ? "visible" : "hidden" }}
            />
          ) : null}
          {!clickChecked && essenceParameter.length ? (
            <FFActionButton
              type={"save"}
              onClick={() => doSaveEssenceParameter()}
              tooltip={"Сохранить"}
              className={classes.margin}
            />
          ) : null}
          {clickChecked ? (
            <FFActionButton
              type={"refresh"}
              onClick={() => doRefresh()}
              tooltip={"Обновить"}
              className={classes.margin}
            />
          ) : null}
          {activeEssence !== null ? (
            <FFActionButton
              type={"split"}
              onClick={() => doChangeParent()}
              tooltip={"Сменить родителя для сущности"}
              disabled={!isFull}
              color={!isFull ? 'gray' : 'primary'}
              className={classes.margin}
            />
          ) : null}
          {activeEssence !== null ? (
            <FFActionButton
              type={"settings"}
              onClick={() => setIsOpenDialogEditTmplate(true)}
              tooltip={"Сменить шаблон"}
              disabled={!isFull}
              color={!isFull ? 'gray' : 'primary'}
              className={classes.margin}
            />
          ) : null}
          {clickChecked && activeEssence !== null ? (
            <FFActionButton
              type={"delete"}
              onClick={() => setIsOpenDialogDelete(true)}
              tooltip={"Удалить"}
              disabled={!isFull}
              color={!isFull ? 'gray' : 'error'}
              className={classes.margin}
            />
          ) : null}
        </Grid>
      </Grid>
      <Paper
        style={{ overflow: "auto", height: "100%", margin: 0, elevation: 0 }}
      >
        <FFContainer
          is_row={true}
          id={"all"}
          components={[
            {
              width: 3,
              elevation: 2,
              component: (
                <FFContainer
                  is_row={true}
                  id={"tree"}
                  components={[
                    {
                      width: 12,
                      elevation: 0,
                      style: !(essenceLoading && essence && essence.length)
                        ? { justifyContent: "center", alignItems: "center" }
                        : { justifyContent: "stretch", alignItems: "stretch" },
                      component: clickChecked ? (
                        <FFLoaderComponent
                          key={clickChecked}
                          loading={essenceLoading && essence && essence.length}
                          isLoader={<FFLoader />}
                          component={
                            <Grid item xs={12}>
                              <Grid item xs={12} style={{ padding: 4 }}>
                                {Object.entries(filterTree).map(
                                  ([key, obj]) => {
                                    return (
                                      <FFActionAvatar
                                        type={obj.icon}
                                        onClick={() =>
                                          toShowManipulationTree(key)
                                        }
                                        badge={
                                          obj.value
                                            ? Array.isArray(obj.value)
                                              ? obj.value.length
                                              : 1
                                            : null
                                        }
                                        tooltip={obj.title}
                                      />
                                    );
                                  }
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                {treeControl}
                              </Grid>
                            </Grid>
                          }
                        />
                      ) : (
                        <FFLoaderComponent
                          key={clickChecked}
                          loading={essenceLoading}
                          isLoader={<FFLoader />}
                          component={
                            <FFTreeEssenceChecked
                              listChecked={listChecked}
                              essence={essence}
                            />
                          }
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              width: 9,
              elevation: 2,
              style: clickChecked
                ? { justifyContent: "center", alignItems: "center" }
                : { justifyContent: "stretch", alignItems: "stretch" },
              component: clickChecked ? (
                <FFLoaderComponent
                  loading={activeEssenceId ? true : false}
                  isLoader={
                    <Typography
                      variant="h1"
                      component="h2"
                      style={{ color: "lightgray", marginTop: "0" }}
                    >
                      Сущность не выбрана
                    </Typography>
                  }
                  component={
                    <FFLoaderComponent
                      loading={
                        activeEssenceId && activeEssenceLoading ? true : false
                      }
                      isLoader={<FFLoader />}
                      component={
                        <FFEssenceCard
                          key={
                            activeEssence
                              ? "FF_Template_Card_" +
                                activeEssence.id +
                                "_" +
                                te +
                                clickChecked
                              : "FF_Template_Card_temp"
                          }
                          classifier={classifier}
                          parameter={parameter}
                          essence={activeEssence ? activeEssence : null}
                          children={children}
                          metaEssence={metaEssence}
                          getBack={getBackToParent}
                        />
                      }
                    />
                  }
                />
              ) : (
                <FFLoaderComponent
                  loading={essenceParameter ? true : false}
                  isLoader={<FFLoader />}
                  component={
                    <FF_MassiveParameters
                      key={
                        "FF_MassiveParameters" +
                        listChecked.length +
                        "_" +
                        clickChecked +
                        te
                      }
                      essenceMassive={essenceMassive}
                      classifier={classifier}
                      parameter={parameter}
                      listChecked={listChecked}
                      essenceParameter={essenceParameter}
                      children={children}
                      activeParameter={activeParameter}
                      doActiveParameter={doActiveParameter}
                      getBackParameter={getBackParameter}
                      metaEssence={metaEssence}
                      getBack={getBackToParent}
                    />
                  }
                />
              ),
            },
          ]}
        />
      </Paper>
    </Context.Provider>
  );
}

const DialogSCV = ({
  open,
  setOpen,
  essence,
  openBackDrop,
  setOpenBackDrop,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  const config = {
    childrenField: "children",
    keyField: "id",
  };
  const treeUtils = new TreeNodeUtils(config);

  const { isLogin } = useContext(Context);
  const [treeEssence, setTreeEssence] = useState([
    { children: essence, id: "root", label: "Все клиенты" },
  ]);
  const classes = newStyles();

  const labelOs = ["Win OS", "Unix"];
  const labelEssence = ["classifiers", "parameters"];
  const chOs = ["w", "u"];
  const [te, setTe] = useState(null);
  const [curParent, setCurParent] = useState("");
  const [typeEssence, setTypeEssence] = useState(0);
  const [typeOs, setTypeOs] = useState(0);
  const [req, setReq] = useState(null);
  const refSelect = useRef(null);
  // const changeValue = (value, id, name, elem) => {
  //   // if (name === 'startCalc') setStartCalc(value)
  //   // if (name === 'finishCalc') setFinishCalc(value)
  // }
  const changeValue = (value, id, name, elem) => {
    console.log(value, id, name, elem);
    if (name === "typeOs") setTypeOs(value);
    else setTypeEssence(value);
  };

  useEffect(() => {}, [refSelect]);

  const doChangeSelect = (par, obj) => {
    setCurParent(obj);
  };
  useEffect(() => {
    if (curParent.length > 0) {
      let obj = treeUtils.getNodeByKey(treeEssence, curParent);
      if (curParent === "root") {
        setReq({ enc: chOs[typeOs] });
      } else if (obj?.type === "client") {
        setReq({ enc: chOs[typeOs], client_id: obj.id });
      } else {
        setReq({ enc: chOs[typeOs], client_id: obj.client, object_id: obj.id });
      }
    }
  }, [typeOs, curParent]);
  return (
    <Context.Provider value={{ changeValue }}>
      {openBackDrop === false ? (
        <Dialog open={open}>
          <DialogTitle style={{ width: 600 }}>
            {"Получить *.csv файл"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <Context.Provider value={{ doChangeSelect }}>
                    <FFSelectTree
                      ref={refSelect}
                      key={"FFSelectTree_temp"}
                      stateMeta={{ open: false, active: -1, label: "" }}
                      idCls={"tempId"}
                      selectValue={curParent}
                      selectMetaLabel={"Выберите клиента или сущность"}
                      selectNodes={treeEssence}
                    />
                  </Context.Provider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <FFSwitch_N
                    id={"typeEssence"}
                    name={"typeEssence"}
                    meta={{
                      label: ["Классификаторы", "Параметры"],
                      width: 150,
                      value: typeEssence,
                    }}
                  />
                  <FFSwitch_N
                    id={"typeOs"}
                    name={"typeOs"}
                    meta={{
                      label: labelOs,
                      width: 100,
                      value: typeOs,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={curParent.length === 0 || openBackDrop ? true : false}
              onClick={(e) => {
                setOpenBackDrop(true);
                ApiConnect({
                  name: "getFile",
                  url: urlReader + `/${labelEssence[typeEssence]}`,
                  objToGet: req,
                  setterEssence: setOpenBackDrop,
                  isLogin: isLogin,
                  clickHandle: handleClickVariant,
                });
              }}
              color="secondary"
            >
              {"Скачать"}
            </Button>
            <Button onClick={(e) => setOpen(false)} color="primary">
              {"Назад"}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Backdrop className={classes.backdrop} open={openBackDrop}>
          <FFLoader />
        </Backdrop>
      )}
    </Context.Provider>
  );
};

const DialogChangeParent = ({
  open,
  setOpen,
  essence,
  activeEssence,
  doRefresh,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  const config = {
    childrenField: "children",
    keyField: "id",
  };
  const treeUtils = new TreeNodeUtils(config);

  const { isLogin } = useContext(Context);
  const [treeEssence, setTreeEssence] = useState([]);
  const [tempAct, setTempAct] = useState({ ...activeEssence });
  const [curParent, setCurParent] = useState("");

  useEffect(() => {
    const clientTree = treeUtils.getNodeByKey(
      JSON.parse(JSON.stringify(essence)),
      activeEssence.client
    );
    let activeNode = treeUtils.getNodeByKey([clientTree], activeEssence.id);
    activeNode?.children && (activeNode.children = []);
    clientTree && setTreeEssence([clientTree]);
  }, []);

  const classes = newStyles();

  const doChangeSelect = (par, id) => {
    delete tempAct.is_deleted;
    if (id === activeEssence.id) {
      setCurParent("");
      handleClickVariant(
        "error",
        "Нельзя текущую сущность, назначить ее родителем"
      );
      return;
    }
    const obj = treeUtils.getNodeByKey(treeEssence, id);
    setCurParent(id);
    if (obj) {
      if (obj.type === "client") {
        tempAct.parent = null;
        tempAct.client = id;
      } else {
        tempAct.parent = id;
      }
      setTempAct(tempAct);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{"Переназначить родителя для сущности"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container item xs={12} spacing={1}>
            <Context.Provider value={{ doChangeSelect }}>
              <FFSelectTree
                key={"FFSelectTree_" + "temp"}
                stateMeta={{ open: false, active: -1, label: "" }}
                idCls={"temp"}
                selectValue={curParent}
                selectMetaLabel={"Выберите клиента или сущность"}
                selectNodes={treeEssence}
              />
            </Context.Provider>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={curParent.length === 0}
          onClick={(e) => {
            ApiConnect({
              name: "updateDate",
              url: urlConfiguration + "/api/?entity=essence",
              objToUpdate: tempAct,
              setterEssence: (temp) => {
                setOpen(false);
                doRefresh();
              },
              clickHandle: handleClickVariant,
              isLogin: isLogin,
            });
          }}
          color="secondary"
        >
          {"Сохранить"}
        </Button>
        <Button onClick={(e) => setOpen(false)} color="primary">
          {"Назад"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
